<template>
  <v-layout class="fill-height overflow-hidden" column>
    <v-card class="custom-card-bg-1 overflow-y-auto overflow-x-hidden pa-2" height="100%" width="100%">
      <v-layout class="fill-height" column>
        <v-overlay :value="loading" absolute>
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        <div style="flex: none; height: 60px; width: 100%; text-align: center;">
          <v-layout align-center class="fill-height px-5">
            <h3 class="text-uppercase">ORDER LIST</h3>
            <TourGuide class="ml-3" ref="tourGuide" v-if="elements" :elements="elements" />
            <v-spacer />
            <div ref="submitBtn">
              <v-btn
                color="primary"
                small
                @click="
                  () => {
                    $refs.makeOrder.openDialog()
                    $refs.tourGuide.completeTour()
                  }
                "
              >
                <v-icon small>mdi-cart-outline</v-icon>
                <span class="ml-2">Submit order</span>
              </v-btn>
            </div>
          </v-layout>
        </div>
        <v-divider />
        <div class="pa-3 pb-2">
          <v-row>
            <v-col cols="12" lg="3" md="3" sm="6" xl="3" xs="12">
              <v-autocomplete
                v-model="filter.service_ids"
                :items="filterData.services"
                clearable
                dense
                hide-details
                item-text="name"
                item-value="id"
                multiple
                outlined
                placeholder="Service"
                label="Service"
                @change="handChangePage(1)"
              ></v-autocomplete>
            </v-col>

            <v-col cols="12" lg="3" md="3" sm="6" xl="3" xs="12">
              <v-autocomplete
                v-model="filter.aoi_uuids"
                :items="filterData.aois"
                clearable
                dense
                hide-details
                item-text="name"
                item-value="uuid"
                multiple
                outlined
                placeholder="AOI"
                label="AOI"
                @change="handChangePage(1)"
              ></v-autocomplete>
            </v-col>

            <v-col cols="12" lg="3" md="3" sm="6" xl="3" xs="12">
              <v-autocomplete
                v-model="filter.image_sources"
                :items="filterData.image_sources"
                clearable
                dense
                hide-details
                multiple
                outlined
                placeholder="Image source"
                label="Image source"
                @change="handChangePage(1)"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" lg="3" md="3" sm="6" xl="3" xs="12">
              <v-autocomplete
                v-model="filter.frequencies"
                :items="filterData.frequencies"
                clearable
                dense
                hide-details
                multiple
                outlined
                placeholder="Frequency"
                label="Frequency"
                @change="handChangePage(1)"
                item-text="name"
                item-value="value"
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="12" lg="3" md="3" sm="6" xl="3" xs="12">
              <v-autocomplete
                v-model="filter.status"
                :items="filterData.status"
                clearable
                dense
                hide-details
                multiple
                outlined
                placeholder="Status"
                label="Status"
                @change="handChangePage(1)"
                item-text="name"
                item-value="value"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" lg="3" md="3" sm="6" xl="3" xs="12">
              <v-layout style="margin: 2px 2px 0;">
                <DateRangerPicker
                  v-model="filter.time"
                  :defaultNull="true"
                  clearable
                  dense
                  label="Select time"
                  left
                  outlined
              /></v-layout>
            </v-col>
            <v-col cols="12" lg="3" md="3" sm="6" xl="3" xs="12" v-if="hasFilter">
              <v-tooltip bottom>
                <template #activator="{ attrs, on }">
                  <v-btn icon @click="clearFilter" v-bind="attrs" v-on="on">
                    <v-icon>mdi-filter-remove-outline</v-icon>
                  </v-btn>
                </template>
                <span>Clear all filter</span>
              </v-tooltip>
            </v-col>
          </v-row>
        </div>
        <v-layout class="pa-3" style="overflow-y: auto; flex: 1">
          <v-data-table
            :headers="headers"
            :items="orders"
            :options.sync="options"
            class="elevation-1"
            fixed-header
            height="100%"
            hide-default-footer
            style="width: 100%"
          >
            <template v-slot:[`item.time`]="{ item }">
              <div>{{ transformDate(item.from_date) }} - {{ transformDate(item.to_date) }}</div>
            </template>

            <template v-slot:[`item.created_at`]="{ item }">
              <div>{{ dateTimeFormatted(item.created_at) }}</div>
            </template>

            <template v-slot:[`item.project`]="{ item }">
              <div>
                <v-layout style="font-size: 13px;">
                  AOI: {{ item.aoi ? item.aoi.name : '' }} /
                  <div v-html="item.aoi ? AreaConvert().covertArea(item.aoi.area) : ''"></div>
                </v-layout>
              </div>
            </template>
            <template #[`item.status`]="{ item }">
              <v-tooltip v-if="item.status === 'failed'" color="#000000" left>
                <template v-slot:activator="{ on, attrs }">
                  <v-chip
                    :color="getStatusColor(item.status)"
                    class="text-capitalize"
                    label
                    small
                    v-bind="attrs"
                    v-on="on"
                  >
                    {{ item.status }}
                  </v-chip>
                </template>
                <span>{{ item.error }}</span>
              </v-tooltip>
              <v-chip v-else :color="getStatusColor(item.status)" class="text-capitalize" label small>
                {{ item.status }}
              </v-chip>
            </template>

            <template #[`item.detail`]="{ item }">
              <div style="text-wrap:nowrap;">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on" @click="showDetail(item)">
                      <v-icon>icon-alert_circle</v-icon>
                    </v-btn>
                  </template>
                  <span>Detail</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      :disabled="item.status !== 'processing'"
                      icon
                      v-bind="attrs"
                      v-on="on"
                      @click="
                        () =>
                          $refs.confirmDialog.openDialog({
                            title: 'Cancel order',
                            content: `Do you want to cancel the order: <b>${item.service_name}</b>`,
                            action: () => cancelOrder(item),
                          })
                      "
                    >
                      <v-icon color="error">mdi-close-circle-outline</v-icon>
                    </v-btn>
                  </template>
                  <span>Cancel</span>
                </v-tooltip>
              </div>
            </template>
          </v-data-table>
        </v-layout>
        <div style="flex: none; width: 100%">
          <div class="mt-3 pb-2 d-flex justify-end">
            <div class="ml-3" style="align-content: end;">Total: {{ totalData }} orders</div>
            <v-spacer />
            <v-pagination
              v-model="page"
              :length="totalPage"
              :total-visible="10"
              circle
              @input="handChangePage"
            ></v-pagination>
          </div>
        </div>
      </v-layout>
    </v-card>

    <order-detail-dialog ref="detailDialog" />
    <MakeOrder ref="makeOrder" />
    <ConfirmDialog ref="confirmDialog" />
  </v-layout>
</template>

<script>
import MakeOrder from '@/views/aoi/order/MakeOrder.vue'
import { getAllOrder, getDataFilterOrder, updateOrder } from '@/api/order'
import { getServices } from '@/api/services'
import convertDate from '@/utils/convertDate'
import DeleteDialog from '@/components/DeleteDialog.vue'
import ThemeSwitcher from '@/layouts/components/ThemeSwitcher.vue'
import AppBarUserMenu from '@/layouts/components/AppBarUserMenu.vue'
import { mapState } from '@/store/ults'
import Status from '@/utils/status'
import DateRangerPicker from '@/components/DateRangerPicker.vue'
import { getListAOI } from '@/api/aoi-api'
import OrderDetailDialog from '@/views/order/OrderDetailDialog.vue'
import AreaConvert from '../../utils/textArea'
import { upperFirst } from 'lodash'
import TourGuide from '@/components/GuideTour/index.vue'
import ConfirmDialog from '@/components/ConfirmDialog.vue'

export default {
  name: 'Orders',
  components: {
    OrderDetailDialog,
    AppBarUserMenu,
    ThemeSwitcher,
    DeleteDialog,
    DateRangerPicker,
    MakeOrder,
    TourGuide,
    ConfirmDialog,
  },
  data() {
    return {
      firstLoad: true,
      keyword: undefined,
      loading: false,
      headers: [
        {
          text: 'Service',
          align: 'start',
          sortable: false,
          value: 'service_name',
        },
        { text: 'Created At', value: 'created_at', sortable: true },
        { text: 'Project/AOI', value: 'project', sortable: false },
        { text: 'Time', value: 'time', sortable: false },
        { text: 'Image Source', value: 'image_source', sortable: false },
        { text: 'Frequency', value: 'frequency', sortable: false },
        { text: 'Status', value: 'status', sortable: false },
        { text: 'Action', value: 'detail', sortable: false, align: 'center' },
      ],
      options: {},
      orders: [],
      page: 1,
      perPage: 10,
      totalPage: 1,
      roles: [],
      totalData: 0,
      filterData: {
        services: [],
        aois: [],
        projects: [],
        frequencies: [],
        image_sources: [],
        status: [],
      },
      filter: {
        status: [],
        image_sources: [],
        frequencies: [],
        time: [],
        aoi_uuids: [],
        service_ids: [],
      },
      elements: undefined,
      orderChannel: undefined,
    }
  },
  computed: {
    ...mapState('auth', ['currentUser']),
    ...mapState('socket', ['echoChannel']),

    notificationId() {
      return this.$route.query?.noti
    },
    hasFilter() {
      return (
        this.filter.status.length > 0 ||
        this.filter.image_sources.length > 0 ||
        this.filter.frequencies.length > 0 ||
        this.filter.aoi_uuids.length > 0 ||
        this.filter.service_ids.length > 0 ||
        (this.filter.time[0] && this.filter.time[1])
      )
    },
  },
  watch: {
    'filter.time': {
      handler(val) {
        if (val && val.length == 2 && !this.firstLoad) {
          this.handChangePage(1)
        }
      },
      deep: true,
    },
    options: {
      handler() {
        if (!this.firstLoad) this.getListOrder()
      },
      deep: true,
    },
    async notificationId() {
      await this.initDataNoti()
    },
  },
  async mounted() {
    this.setDefaultFilter()
    await this.initDataNoti()
    this.initListen()
    this.elements = [
      {
        ref: this.$refs.submitBtn,
        id: 'SubmitOrder',
        title: 'Getting Started',
        text: 'Create your order ',
        position: 'left',
      },
    ]
  },
  beforeDestroy() {
    if (this.$refs.tourGuide) this.$refs.tourGuide.completeTour()
  },
  methods: {
    initListen() {
      if (this.orderChannel) {
        this.echoChannel.private(this.orderChannel).unsubscribe()
      }
      this.orderChannel = `project.${this.$route.params.id}`
      this.echoChannel
        .private(`project.${this.$route.params.id}`)
        .listen('.order.changed', e => {
          this.getListOrder()
        })

    },
    setDefaultFilter() {
      if (this.$route.query) {
        if (this.$route.query.aoiId) {
          this.filter.aoi_uuids = [this.$route.query.aoiId]
        }

        if (this.$route.query.source) {
          this.filter.image_sources = [this.$route.query.source]
        }
        if (this.$route.query.service) {
          let services = this.$route.query.service
          if (Array.isArray(services)) services = services.map(service => Number(service))
          else services = Number(services)
          this.filter.service_ids = services
        }
      }
    },
    clearFilter() {
      this.filter = {
        status: [],
        image_sources: [],
        frequencies: [],
        time: [],
        aoi_uuids: [],
        service_ids: [],
      }
    },
    async initDataNoti() {
      const query = this.$route.query
      if (query.notification && this.notificationId) {
        const noti = await this.$store.dispatch('notification/getNotification', query.noti)
        const reference_data = noti.reference_data
        this.filter = {
          ...this.filter,
          status: [reference_data?.order_status],
          aoi_uuids: [reference_data?.aoi?.uuid],
          service_ids: [reference_data?.service?.id],
          image_sources: [reference_data?.image_source],
          frequencies: [reference_data?.frequency],
        }

        this.isNotification = true
      }
      await this.getListOrder()

      this.getDataFilter()
      this.firstLoad = false
    },
    AreaConvert() {
      return AreaConvert
    },
    async cancelOrder(service) {
      try {
        this.loading = true
        const res = await updateOrder({
          projectId: this.$route.params.id,
          AOIId: service.aoi.uuid,
          orderId: service.uuid,
        })
        this.data = res.data
        this.dialog = true
      } catch (e) {
        this.loading = false
      } finally {
        // await this.getListOrder()
      }
    },
    async getListOrder() {
      try {
        this.loading = true
        const fitler = {}
        for (const property in this.filter) {
          if (this.filter[property] && this.filter[property].length > 0) {
            fitler[property] = this.filter[property]
          }
        }
        const res = await getAllOrder({
          projectId: this.$route.params.id,
          payload: {
            lifetime: false,
            per_page: this.perPage,
            page: this.page,
            sort: this.options.sortBy[0]
              ? !this.options.sortDesc[0]
                ? this.options.sortBy[0]
                : `-${this.options.sortBy[0]}`
              : undefined,
            ...fitler,
          },
        })
        this.orders = res.data.data

        this.totalPage = res.data.last_page
        this.totalData = res.data.total
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    showDetail(item) {
      this.$refs.detailDialog.showDetail(item)
    },
    async getDataFilter() {
      this.getService()
      this.getListAOI()
      let data = await getDataFilterOrder()
      this.filterData.frequencies = data.data.frequencies.map(f => {
        return {
          name: upperFirst(f),
          value: f,
        }
      })
      this.filterData.status = data.data.status.map(s => {
        return {
          name: upperFirst(s),
          value: s,
        }
      })
      this.filterData.image_sources = data.data.image_sources
    },
    transformDate(date) {
      return convertDate.dateFormatted(date)
    },
    dateTimeFormatted(date) {
      return convertDate.dateTimeFormatted(date)
    },
    async getService() {
      let data = await getServices()
      this.filterData.services = data.data.sort((a, b) => {
        let nameA = a.name.toUpperCase()
        let nameB = b.name.toUpperCase()

        if (nameA < nameB) {
          return -1
        }
        if (nameA > nameB) {
          return 1
        }
        return 0
      })
    },
    async getListAOI() {
      let data = await getListAOI({
        projectId: this.$route.params.id,
        payload: { per_page: 'all' },
      })
      this.filterData.aois = data.data
    },
    getStatusColor(status) {
      return Status.getColor(status)
    },
    async handChangePage(page) {
      this.page = page
      await this.getListOrder()
    },
  },
}
</script>
