<template>
  <v-dialog v-model="dialog" persistent width="500">
    <v-card height="100%" width="100%">
      <DialogHeader :is-close="true" :title="title" @close="dialog = false" />
      <v-card-text>
        <div>
          <div v-html="content"></div>
        </div>
        <v-layout class="pt-3">
          <v-spacer />
          <v-btn class="text-none" color="error" :loading="loading" rounded @click="submitData">
            {{  textConfirmBtn }}
          </v-btn>
        </v-layout>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import DialogHeader from '@/components/DialogHeader.vue'

export default {
  name: 'ConfirmDialog',
  props: {
    textConfirmBtn: {
      type: String,
      default: 'Confirm',
    },
  },
  components: { DialogHeader },
  data() {
    return {
      dialog: false,
      loading: false,
      currentItem: {},
      title: 'Confirm',
      content: 'Are you sure ?',
      onSubmit: () => {},
    }
  },

  methods: {
    openDialog(item) {
      this.dialog = true
      this.title = item.title
      this.content = item.content
      this.onSubmit = item.action
    },
    async submitData() {
      try {
        this.loading = true
        await this.onSubmit()
      } catch(e) {
        console.warn('Error when submit confirm: ', e)
      } finally {
        this.loading = false
        this.dialog = false
      }
    },
  },
}
</script>
