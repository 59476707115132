<template>
  <v-dialog v-model="detailDialog" persistent width="575">
    <v-card :disabled="loading" :loading="loading" class="custom-card-bg-gradient" height="100%" width="100%">
      <DialogHeader :is-close="true" :title="'Order Information'" @close="detailDialog = false" />
      <v-card-text style="min-height:70px">
        <v-layout column>
          <div
            v-if="currentOrder && currentOrder.status === 'failed'"
            class="pb-3"
            style="color: red; font-size: 16px;"
          >
            Error: {{ currentOrder.error }}
          </div>
          <v-data-table
            v-if="currentOrder && !currentOrder.error"
            :headers="detailHeaders"
            :items="orderData"
            :items-per-page="5"
            :page.sync="page"
            hide-default-footer
            item-key="uuid"
            @page-count="pageCount = $event"
          >
            <template #[`item.date`]="{item}">
              <v-icon :color="getStatusColor(item.status)" class="mr-2">mdi-memory</v-icon>
              {{ item.date }}
            </template>
            <template #[`item.status`]="{item}">
              <v-chip :color="getStatusColor(item.status)" small>{{ item.status }}</v-chip>
            </template>
          </v-data-table>
          <div v-if="currentOrder && !currentOrder.error" class="text-end py-2 pl-4">
            <v-layout align-center>
              <div style="max-width: 100px">
                <v-layout align-center>
                  <span class="caption">Page:</span>
                  <v-text-field
                    v-model="pageSelected"
                    :max="pageCount"
                    class="ml-1"
                    dense
                    flat
                    hide-details
                    label="page"
                    min="1"
                    placeholder="page"
                    solo
                    type="number"
                    @change="changePage"
                  />
                </v-layout>
              </div>
              <v-spacer />
              <v-pagination v-model.number="page" :length="pageCount" total-visible="5"></v-pagination>
            </v-layout>
          </div>
        </v-layout>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import DialogHeader from '@/components/DialogHeader.vue'
import Status from '@/utils/status'
import { getOrderStatus } from '@/api/order'

export default {
  components: { DialogHeader },
  data() {
    return {
      currentOrder: undefined,
      detailDialog: false,
      loading: false,
      detailHeaders: [
        {
          text: 'Date',
          align: 'start',
          sortable: true,
          value: 'date',
        },
        {
          text: 'Status',
          align: 'end',
          sortable: false,
          value: 'status',
        },
      ],
      orderData: [],
      page: 1,
      pageCount: 0,
      pageSelected: 1,
    }
  },
  methods: {
    showDetail(order) {
      this.currentOrder = order
      this.detailDialog = true
      this.getStatus(order)
    },
    async getStatus(order) {
      try {
        this.loading = true
        this.orderData = []
        const res = await getOrderStatus({
          projectId: this.$route.params.id,
          id: order.aoi.uuid,
          orderId: order.uuid,
          payload: { per_page: 'all' },
        })
        this.orderData = res.data
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    getStatusColor(status) {
      return Status.getColor(status)
    },
    changePage(page) {
      if (page < 1) {
        this.page = 1
        this.pageSelected = 1
      } else if (page > this.pageCount) {
        this.page = this.pageCount
        this.pageSelected = this.pageCount
      } else this.page = Number(page)
    },
  },
}
</script>
<style scoped></style>
